<template>
  <v-dialog
    v-model="dialogImage"
    @click:outside="$emit('update:dialogImage', false)"
    @keydown.esc="$emit('update:dialogImage', false)"
    width="700px"
  >
    <v-card class="pa-1">
      <v-img :src="item"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogImage",

  props: {
    dialogImage: {
      type: Boolean,
      default: false,
    },
    item: {
      type: String,
    },
  },
};
</script>

<style></style>
